import { reportBug } from 'utils';
import { Bot, BotCard, CreateBotDetails, BotCreation, FetchBotsParams, FetchBotsResponse, SearchParams, SearchResponse, SearchBotCardRead } from './types';
import apiCall from 'api';

export const fetchBots = async ({ authorized = false, ...params }: FetchBotsParams & { authorized?: boolean }) => {
  try {
    const request = await apiCall(authorized);
    const { data } = await request<FetchBotsResponse>({ url: `bots`, method: 'GET', params });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.detail?.msg, error });
    return Promise.reject(error);
  }
};

const transformSearchResultToBotCard = (searchResult: SearchBotCardRead): BotCard => {
  return {
    id: searchResult.card.id,
    key: searchResult.card.key,
    bot_id: searchResult.bot.id,
    bot_name: searchResult.bot.name,
    photo_url: searchResult.asset?.url || searchResult.card.photo_url,
    is_unlocked: searchResult.card.is_unlocked,
    custom_attributes: {
      ...searchResult.card.custom_attributes,
      scenario: searchResult.card.custom_attributes.scenario || '',
      bot_role: searchResult.card.custom_attributes.bot_role || '',
      tags: searchResult.card.custom_attributes.tags || [],
      reported_reasons: []
    },
    created_at: searchResult.created_at
  };
};

export const searchBots = async (params: SearchParams) => {
  try {
    const request = await apiCall();
    const { data } = await request<SearchResponse>({ url: `bots/search`, method: 'GET', params });
    return {
      data: data.data.data,
      total: data.data.total,
      count: data.data.count,
      search_id: data.data.search_id
    };
  } catch (error: any) {
    reportBug({ msg: error?.detail?.msg, error });
    return Promise.reject(error);
  }
};

export const searchCards = async (params: SearchParams) => {
  try {
    const request = await apiCall();
    const { data } = await request<SearchResponse>({ url: `bots/cards/search`, method: 'GET', params });

    return {
      data: (data.data.data as SearchBotCardRead[]).map(transformSearchResultToBotCard),
      total: data.data.total,
      count: data.data.count,
      search_id: data.data.search_id
    };
  } catch (error: any) {
    reportBug({ msg: error?.detail?.msg, error });
    return Promise.reject(error);
  }
};


export const recordSearchClick = async (searchId: string, resourceId: number) => {
  try {
    const request = await apiCall();
    await request({
      url: `bots/searches/${searchId}/click/${resourceId}`,
      method: 'POST'
    });
    return true;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return false;
  }
};


export const fetchBot = async (id: string | number | null) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/${id}`, method: 'GET' });

    return data.data as Bot;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchBotCards = async (id: string | number | null) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/${id}/cards`, method: 'GET' });

    return data.data.data as BotCard[];
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchBotCard = async (bot_id: string | number | null, key: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/${bot_id}/cards/${key}`, method: 'GET' });

    return data.data as BotCard;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchCard = async (key: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/cards/${key}`, method: 'GET' });

    return data.data as BotCard;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const unlockBotCard = async (bot_id: string | number | null, key: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${bot_id}/cards/${key}/unlock`,
      method: 'POST'
    });

    return data.data as BotCard;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const activateBotCard = async (bot_id: string | number | null, key: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${bot_id}/cards/${key}/activate`,
      method: 'POST'
    });

    return data.data as BotCard;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const deactivateBotCard = async (bot_id: string | number | null) => {
  try {
    const request = await apiCall();
    await request({
      url: `bots/${bot_id}/cards/deactivate`,
      method: 'POST'
    });

    return true;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const fetchCreations = async (): Promise<BotCreation[]> => {
  try {
    const request = await apiCall();
    const { data } = await request({ url: `bots/creations`, method: 'GET' });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return [];
  }
};

export const getCreation = async (id: string): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/creations/${id}`,
      method: 'GET'
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};

export const generateBot = async (
  botAttributes: Record<string, string>,
  photo?: File | null
): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const formData = new FormData();

    formData.append('data', JSON.stringify(botAttributes));
    if (photo instanceof File) {
      formData.append('photo', photo);
    }

    const [{ data }] = await Promise.all([
      request({
        url: 'bots/creations/new',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      new Promise(resolve => setTimeout(resolve, 5000))
    ]);
    return data.data;
  } catch (error: any) {
    const msg = error?.data?.message || error?.data?.detail?.[0]?.msg || 'Failed to generate Krush';
    reportBug({ msg, error, prodToast: true });
    throw error;
  }
};

export const regenerateBot = async (id: string): Promise<BotCreation> => {
  try {
    const request = await apiCall();
    const [{ data }] = await Promise.all([
      request({ url: `bots/creations/${id}/regenerate`, method: 'POST' }),
      new Promise(resolve => setTimeout(resolve, 5000)) // 5-second delay
    ]);
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};

export const createFinalizeDraft = async (draftData: { details: CreateBotDetails; id: string; draft_id: number }) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/creations/${draftData.id}/draft/${draftData.draft_id}/finalize`,
      method: 'POST',
      data: { ...draftData.details }
    });
    return data.data;
  } catch (error: any) {
    const msg = error?.data?.message || error?.data?.detail?.[0]?.msg || 'Failed to finalize Krush';
    reportBug({ msg, error, prodToast: true });
    throw error;
  }
};

export const fetchCreationWith3Drafts = async (botAttributes: Record<string, string>) => {
  try {
    let result: BotCreation = await generateBot(botAttributes);

    while (result?.drafts?.length < 3) {
      result = await regenerateBot(result.id.toString());
    }

    return result;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const deleteCreation = async (id: number) => {
  try {
    const request = await apiCall();
    await request({ url: `bots/creations/${id}`, method: 'DELETE' });
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    throw error;
  }
};

type SharedMediaParams = {
  user_media_id: string | number;
  key: string;
};

export const fetchSharedMedia = async ({ user_media_id, key }: SharedMediaParams) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/sharePhoto/${user_media_id}`,
      method: 'GET',
      params: { key }
    });

    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export type CreateCardAttributes = {
  tagline?: string;
  scenario: string;
  bot_role: string;
  user_role?: string;
  greeting_message?: string;
  tags?: string[];
  behavior?: string;
  personality?: string;
  sexuality?: string;
  outfit?: string;
  scenario_secret?: string;
  creator_notes?: string;
  message_examples?: string;
}

export const createCard = async (
  botId: string,
  attributes: CreateCardAttributes,
  parentKey?: string | null
): Promise<BotCard> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${botId}/cards/new`,
      method: 'POST',
      params: parentKey ? { parent_key: parentKey } : undefined,
      data: attributes
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

export const editCard = async (
  cardKey: string,
  attributes: CreateCardAttributes
): Promise<BotCard> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/cards/${cardKey}`,
      method: 'PUT',
      data: attributes
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};


export const importCardData = async (
  inputData: string,
): Promise<CreateCardAttributes> => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/cards/importData`,
      method: 'POST',
      params: { data: inputData }
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};


export const reportBotCard = async (botId: string | number | null, cardKey: string, reason: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${botId}/cards/${cardKey}/report`,
      method: 'POST',
      params: { reason }
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};


export const regenerateBotCardPhoto = async (botId: string , cardKey: string) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${botId}/cards/${cardKey}/regeneratePhoto`,
      method: 'POST'
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};

type PhotoGenerationData = {
  prompt: string;
  level: string;
  metadata: Record<string, any>;
};

export const generateBotPhoto = async (botId: string, photoData: PhotoGenerationData) => {
  try {
    const request = await apiCall();
    const { data } = await request({
      url: `bots/${botId}/generatePhoto`,
      method: 'POST',
      data: photoData
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};


export const fetchBotCardsByList = async (botCardIds: number[]) => {
  try {
    const request = await apiCall();
    const params = new URLSearchParams();

    // Add each ID as a separate bot_card_ids parameter
    botCardIds.forEach(id => {
      params.append('bot_card_ids', id.toString());
    });

    const { data } = await request({
      url: `bots/cards/list?${params.toString()}`,
      method: 'GET'
    });
    return data.data;
  } catch (error: any) {
    reportBug({ msg: error?.data?.message, error });
    return Promise.reject(error);
  }
};
