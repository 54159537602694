import { Bot, BotCard } from 'api/bot/types';
import Link from 'next/link';
import { useAuthStore } from 'store/auth';
import { Tag } from 'components/ui/Tag';

type Props = {
  data: Bot | BotCard;
  onClick?: () => void;
};

export const BotCardItem = ({ data, onClick }: Props) => {
  // Helper function to determine if data is Bot or BotCard type
  const isBot = (data: Bot | BotCard): data is Bot => {
    return 'name' in data && !('key' in data);
  };

  const { user } = useAuthStore();

  let id, botName, cardTitle, seoDetails, imageUrl, subtitle, tags, hrefUrl;

  try {
    // Get the correct values based on data type
    id = isBot(data) ? data.id : data.bot_id;
    botName = isBot(data) ? data.name : data.bot_name;
    cardTitle = isBot(data) ? botName : data.custom_attributes?.tagline;
    seoDetails = user ? cardTitle : cardTitle + ' - Free AI Girlfriend and NSFW Chatbot at Krush.chat';
    imageUrl = isBot(data)
      ? data.custom_attributes?.avatar_url
      : data.photo_url;
    subtitle = isBot(data)
      ? data.custom_attributes?.subtitle || data.custom_attributes?.description
      : null;
    tags = !isBot(data) ? data.custom_attributes?.tags : null;
    hrefUrl = isBot(data)
      ? `/c/${id}/${data.bot_name}`
      : `/c/${id}/${data.bot_name}/cards/${data.key}`;
  } catch (error) {
    console.error('Error processing bot/card data:', error, data);
    return null;
  }

  return (
    <Link
      prefetch={false}
      className="cy-bot-card relative border-2 border-primary rounded-xl overflow-hidden cursor-pointer trans hover:border-brand lg:hover:scale-105"
      href={hrefUrl}
      title={seoDetails}
      onClick={onClick}
    >
      {imageUrl && (
        <div className="relative w-full h-[250px] lg:h-[400px] object-cover object-top z-10">
          <img
            src={imageUrl}
            srcSet={`${imageUrl} 2x`}
            alt={seoDetails}
            className="w-full h-full object-cover object-top"
          />
        </div>
      )}

      <div className="absolute bottom-0 z-20 bg-gradient-to-t from-[#1f1f1f] to-transparent h-28 w-full" />
      <div className="absolute bottom-0 z-30 flex flex-col md:gap-1 p-3 w-full">
        {isBot(data) ? (
          // Bot display pattern
          <>
            <h2 className="text-xl font-medium">{cardTitle}</h2>
            {subtitle && (
              <p className="subtitle-ellipsis text-sm text-stone-400">
                {subtitle}
              </p>
            )}
          </>
        ) : (
          // Card display pattern
          <>
            <Link
              href={`/c/${id}/${data.bot_name}`}
              onClick={(e) => e.stopPropagation()}
              className="text-sm text-stone-300 drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)] hover:text-brand transition-colors"
            >
              {botName}
            </Link>
            <h2 className="text-xl font-medium drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">{cardTitle}</h2>
            {tags && tags.length > 0 && (
              <div className="flex items-center mt-1 overflow-hidden group-hover:overflow-visible">
                <div className="flex gap-1 overflow-x-hidden hover:flex-wrap transition-all">
                  {tags.map((tag) => (
                    <Tag key={tag} tag={tag} isCard={true} />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Link>
  );
};